import axios from 'axios'

const API_BASE_URL = process.env.REACT_APP_SEVERLESS_BASE_URL

export const login = async (email: string, password: string) => {
  try {
    const loginUrl = `${API_BASE_URL}/login`
    const response = await axios.post(loginUrl, {
      email,
      password
    })
    const { data, headers } = response
    const authToken =
      headers.authorization || headers['x-amzn-remapped-authorization']
    if (null === authToken) {
      // TODO!
    }
    localStorage.setItem('authToken', authToken)
    return data
  } catch (error) {
    throw new Error('Login failed')
  }
}

export const logout = async () => {
  try {
    localStorage.removeItem('authToken')
  } catch (error) {
    throw new Error('Login failed')
  }
}

export const requestPasswordReset = async (email: string) => {
  try {
    const url = `${API_BASE_URL}/requestPasswordReset`
    const response = await axios.post(url, {
      email
    })
    //const { data, headers } = response
    if (200 === response.status) {
      const { data } = response
      return data
    }
  } catch (error) {
    throw new Error('Reset password failed')
  }
}

export const resetPassword = async (token: string, password: string) => {
  try {
    const url = `${API_BASE_URL}/resetPassword`
    const response = await axios.post(url, {
      token,
      newPassword: password
    })
    //const { data, headers } = response
    if (200 === response.status) {
      return true
    }
  } catch (error) {
    throw new Error('Reset password failed')
  }
}
