import React, { useState } from 'react'
import PaddedField from '../templates/PaddedField'
import Input from '../atoms/Input'
import LoadingOverlay from '../atoms/LoadingOverlay'
import { requestPasswordReset } from '../../services/authService'

const RequestPasswordResetForm = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleRequestPasswordChange = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSuccessMessage(null)
    event.preventDefault()
    setIsLoading(true)
    try {
      await requestPasswordReset(email)
      setErrorMessage(null)
      setSuccessMessage(
        'A password reset request has been made. Please check your email inbox / spam folder for a password reset link.'
      )
      // Redirect to homepage or dashboard after successful login
    } catch (error) {
      setErrorMessage((error as Error).message)
      console.error(error)
      // Handle login error (e.g., show an error message)
    }
    setIsLoading(false)
  }

  return (
    <div>
      <LoadingOverlay enabled={isLoading} />
      {errorMessage ? (
        <div className='bg-red-500 text-white p-4 m-4 rounded-md'>
          {errorMessage}
        </div>
      ) : null}
      {successMessage ? (
        <div className='bg-blue-500 text-white p-4 m-4 rounded-md'>
          {successMessage}
        </div>
      ) : null}
      <form
        className='flex flex-col items-center space-y-4'
        style={{ display: successMessage ? 'none' : 'flex' }}
      >
        <div className='space-y-4'>
          <PaddedField>
            <Input
              type='text'
              name='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Email'
            />
          </PaddedField>
          <button
            className='px-4 py-2 bg-purple-500 text-white rounded'
            onClick={handleRequestPasswordChange}
          >
            Request Password Reset
          </button>
        </div>
      </form>
    </div>
  )
}

export default RequestPasswordResetForm
