import React, { useState } from 'react'
import { useLocation } from 'wouter'
import PaddedField from '../templates/PaddedField'
import Input from '../atoms/Input'
import Checkbox from '../atoms/Checkbox'
import LoadingOverlay from '../atoms/LoadingOverlay'
import { login } from '../../services/authService'
import { useSetRecoilState } from 'recoil'
import { isLoggedInState } from '../../state/authState'

const LoginForm = () => {
  const [, navigate] = useLocation()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [email, setEmail] = useState(localStorage.getItem('email') || '')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(
    null !== localStorage.getItem('rememberMe')
  )
  const setIsLoggedIn = useSetRecoilState(isLoggedInState)
  const [isLoading, setIsLoading] = useState(false)

  const handleLogin = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setIsLoading(true)
    try {
      await login(email, password)
      if (rememberMe) {
        localStorage.setItem('email', email)
        localStorage.setItem('rememberMe', 'true')
      } else {
        localStorage.removeItem('email')
        localStorage.removeItem('rememberMe')
      }
      setIsLoggedIn(true)
      // Redirect to homepage or dashboard after successful login
    } catch (error) {
      setErrorMessage((error as Error).message)
      console.error(error)
      // Handle login error (e.g., show an error message)
    }
    setIsLoading(false)
  }

  return (
    <div>
      <LoadingOverlay enabled={isLoading} />
      {errorMessage ? (
        <div className='bg-red-500 text-white p-4 m-4 rounded-md'>
          {errorMessage}
        </div>
      ) : null}
      <form className='flex flex-col items-center space-y-4'>
        <div className='space-y-4'>
          <PaddedField>
            <Input
              type='text'
              name='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Email'
            />
          </PaddedField>
          <PaddedField>
            <Input
              type='password'
              name='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder='Password'
            />
          </PaddedField>
          <PaddedField>
            <Checkbox
              label='Remember me?'
              name='rememberMe'
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
            />
          </PaddedField>
          <button
            className='px-4 py-2 bg-purple-500 text-white rounded'
            onClick={handleLogin}
          >
            Login
          </button>
          <button
            className='px-4 py-2 bg-white text-blue-800 underline'
            onClick={() => {
              navigate('/requestPasswordReset')
            }}
          >
            Forgot password?
          </button>
        </div>
      </form>
    </div>
  )
}

export default LoginForm
