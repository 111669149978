import React, { ReactNode } from 'react'

interface PaddedFieldProps {
  children: ReactNode
  topPadding?: number
  bottomPadding?: number
}

const PaddedField: React.FC<PaddedFieldProps> = ({
  children,
  topPadding = 2,
  bottomPadding = 0
}) => {
  return (
    <div className={`pt-${topPadding} pb-${bottomPadding}`}>{children}</div>
  )
}

export default PaddedField
