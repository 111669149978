import React from 'react'
import RequestPasswordResetForm from '../components/molecules/RequestPasswordResetForm'

const RequestPasswordResetPage = () => (
  <div className='my-4'>
    <RequestPasswordResetForm />
  </div>
)

export default RequestPasswordResetPage
