import React, { useState } from 'react'
import { useLocation } from 'wouter'
import PaddedField from '../templates/PaddedField'
import Input from '../atoms/Input'
import LoadingOverlay from '../atoms/LoadingOverlay'
import { resetPassword } from '../../services/authService'

const useQuery = () => {
  const [location] = useLocation()
  const search = location.split('?')[1] || '' || window.location.search
  return new URLSearchParams(search)
}

const ResetPasswordForm = () => {
  const [, navigate] = useLocation()
  const query = useQuery()
  const token = query.get('token') || ''
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handlePasswordChange = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSuccessMessage(null)
    event.preventDefault()
    if (password !== password2) {
      setSuccessMessage(null)
      setErrorMessage('Passwords do not match!')
      return
    }
    setIsLoading(true)
    try {
      await resetPassword(token, password)
      setErrorMessage(null)
      setSuccessMessage(
        'Your password has been reset! You will now be redirected....'
      )
      setTimeout(() => {
        navigate('/login')
      }, 3000)
      // Redirect to homepage or dashboard after successful login
    } catch (error) {
      console.error(error)
      // Handle login error (e.g., show an error message)
    }
    setIsLoading(false)
  }

  return (
    <div>
      <LoadingOverlay enabled={isLoading} />
      {errorMessage ? (
        <div className='bg-red-500 text-white p-4 m-4 rounded-md'>
          {errorMessage}
        </div>
      ) : null}
      {successMessage ? (
        <div className='bg-blue-500 text-white p-4 m-4 rounded-md'>
          {successMessage}
        </div>
      ) : null}
      <form
        className='flex flex-col items-center space-y-4'
        style={{ display: successMessage ? 'none' : 'flex' }}
      >
        <div className='space-y-4'>
          <PaddedField>
            <Input
              type='password'
              name='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder='Password'
            />
          </PaddedField>
          <PaddedField>
            <Input
              type='password'
              name='password2'
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              placeholder='Retype Password'
            />
          </PaddedField>
          <button
            className='px-4 py-2 bg-purple-500 text-white rounded'
            onClick={handlePasswordChange}
          >
            Reset Password
          </button>
        </div>
      </form>
    </div>
  )
}

export default ResetPasswordForm
