import React from 'react'
import Tabs from './Tabs'
import EventLog from './EventLog'
import CorrelationPlayground from './CorrelationPlayground'
import { PoleData, PoleEventData } from '../../types/poles/PoleData'
import {
  coronaLevelToShortCategory,
  surfaceDischargeLevelToShortCategory,
  arcingLevelToShortCategory
} from '../../services/poleServices'

interface PoleInfoParams {
  pole: PoleData
  eventColors: { [color: string]: string }
}

const PoleInfo: React.FC<PoleInfoParams> = ({ pole, eventColors }) => {
  const events: PoleEventData[] = pole.events
  //events.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime())

  const poleInformation = (
    <div className='flex justify-center'>
      <table className='divide-y divide-gray-200 mt-4'>
        <thead className='bg-gray-50'>
          <tr>
            <th className='px-6 py-3 text-left text-sm font-medium text-gray-500 tracking-wider'>
              Property
            </th>
            <th className='px-6 py-3 text-left text-sm font-medium text-gray-500 tracking-wider'>
              Value
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className='cursor-pointer hover:bg-gray-100'>
            <td className='px-6 py-4 whitespace-nowrap'>Vegetation Type</td>
            <td className='px-6 py-4 whitespace-nowrap'>Grass</td>
          </tr>
          <tr className='cursor-pointer hover:bg-gray-100'>
            <td className='px-6 py-4 whitespace-nowrap'>Line</td>
            <td className='px-6 py-4 whitespace-nowrap'>
              {pole.info.lineNumber}
            </td>
          </tr>
          <tr className='cursor-pointer hover:bg-gray-100'>
            <td className='px-6 py-4 whitespace-nowrap'>Pole Number</td>
            <td className='px-6 py-4 whitespace-nowrap'>
              {pole.info.poleNumber}
            </td>
          </tr>
          <tr className='cursor-pointer hover:bg-gray-100'>
            <td className='px-6 py-4 whitespace-nowrap'>Insulator Type</td>
            <td className='px-6 py-4 whitespace-nowrap'>
              {pole.info.insulatorType}
            </td>
          </tr>
          <tr className='cursor-pointer hover:bg-gray-100'>
            <td className='px-6 py-4 whitespace-nowrap'>
              Linesmen&apos;s Notes
            </td>
            <td className='px-6 py-4 whitespace-nowrap'>None</td>
          </tr>
        </tbody>
      </table>
    </div>
  )

  const allReadings = (
    <div className='flex justify-center'>
      <table className='min-w-full divide-y divide-gray-200 mt-4'>
        <thead className='bg-gray-50'>
          <tr>
            <th className='px-6 py-3 text-left text-sm font-medium text-gray-500 tracking-wider'>
              Date & Time
            </th>
            <th className='px-6 py-3 text-left text-sm font-medium text-gray-500 tracking-wider'>
              Corona Severity
            </th>
            <th className='px-6 py-3 text-left text-sm font-medium text-gray-500 tracking-wider'>
              Surface Discharge Severity
            </th>
            <th className='px-6 py-3 text-left text-sm font-medium text-gray-500 tracking-wider'>
              Arcing Severity
            </th>
            <th className='px-6 py-3 text-left text-sm font-medium text-gray-500 tracking-wider'>
              Temperature
            </th>
            <th className='px-6 py-3 text-left text-sm font-medium text-gray-500 tracking-wider'>
              Humidity
            </th>
            <th className='px-6 py-3 text-left text-sm font-medium text-gray-500 tracking-wider'>
              Pressure
            </th>
          </tr>
        </thead>
        <tbody>
          {events.map((event, i) => {
            const formattedDate =
              event.timestamp.toISOString().split('T')[0] +
              ' ' +
              event.timestamp.toTimeString().split(' ')[0].slice(0, 5)
            return (
              <tr key={i} className='cursor-pointer hover:bg-gray-100'>
                <td className='px-6 py-4 whitespace-nowrap'>{formattedDate}</td>
                <td className='px-6 py-4 whitespace-nowrap'>
                  {coronaLevelToShortCategory(event.coronaLevel)}
                </td>
                <td className='px-6 py-4 whitespace-nowrap'>
                  {surfaceDischargeLevelToShortCategory(
                    event.surfaceDischargeLevel
                  )}
                </td>
                <td className='px-6 py-4 whitespace-nowrap'>
                  {arcingLevelToShortCategory(event.arcingLevel)}
                </td>
                <td className='px-6 py-4 whitespace-nowrap'>
                  {event.temperature}&deg;C
                </td>
                <td className='px-6 py-4 whitespace-nowrap'>
                  {event.humidity}%
                </td>
                <td className='px-6 py-4 whitespace-nowrap'>
                  {event.pressure} Pa
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )

  const graph = (
    <div>
      <EventLog events={events} eventColors={eventColors} />
    </div>
  )

  const correlationPlayground = (
    <div>
      <CorrelationPlayground events={events} eventColors={eventColors} />
    </div>
  )

  const tabs = [
    { label: 'All Readings', content: allReadings },
    { label: 'Event Graph', content: graph },
    { label: 'Correlation Playground', content: correlationPlayground },
    { label: 'Pole Information', content: poleInformation }
  ]

  return (
    <div className='p-6'>
      <Tabs tabs={tabs} />
    </div>
  )
}

export default PoleInfo
