import React, { useState, useEffect } from 'react'
import {
  loadPoleEventData,
  coronaLevelToCategory,
  surfaceDischargeLevelToCategory,
  arcingLevelToCategory,
  colorToHealthExplanation
} from '../services/poleServices'

import { PoleEventData } from '../types/poles/PoleData'

import CountUp from 'react-countup'
import LoadingOverlay from '../components/atoms/LoadingOverlay'

//import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet.heat'
//delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

import { createMarkerIcon } from '../services/iconService'

const greyIcon = createMarkerIcon('grey')
console.log('$$$$$$$$$$$$$$$$$$$4')
console.log(greyIcon.options.html)
console.log('$$$$$$$$$$$$$$$$$$$4')
const blueIcon = createMarkerIcon('blue')
const greenIcon = createMarkerIcon('green')
const yellowIcon = createMarkerIcon('yellow')
const orangeIcon = createMarkerIcon('orange')
const redIcon = createMarkerIcon('red')

import Dropdown from '../components/atoms/Dropdown'
import Checkbox from '../components/atoms/Checkbox'
import Accordion from '../components/atoms/Accordion'
import PoleInfo from '../components/organisms/PoleInfo'
import { usePoleData } from '../hooks/usePoleData'
import { PoleData } from '../types/poles/PoleData'

interface HeatmapLayerProps {
  points: [number, number, number][]
}

const HeatmapLayer: React.FC<HeatmapLayerProps> = ({ points }) => {
  const map = useMap()

  useEffect(() => {
    const heatLayer = L.heatLayer(points, { radius: 25, blur: 15 }).addTo(map)

    const updateHeatmap = () => {
      const zoom = map.getZoom()
      const radius = ((18 - zoom) * 2 + 1) * 25
      const blur = ((18 - zoom) * 2 + 1) * 15
      heatLayer.setOptions({ radius, blur })
      heatLayer.redraw()
    }

    map.on('zoomend', updateHeatmap)

    return () => {
      map.removeLayer(heatLayer)
      map.off('zoomend', updateHeatmap)
    }
  }, [map, points])

  return null
}

interface LoadedEvents {
  [id: number | string]: {
    loadedAt: Date
    events: PoleEventData[]
  }
}

const TransmissionLinePage = () => {
  const [loadedEventsByNodeId, setLoadedEventsByNodeId] =
    useState<LoadedEvents>({})
  const [loadingData, setLoadingData] = useState(false)
  const { poles } = usePoleData({ setLoading: setLoadingData })
  const heatMapPoints: [number, number, number][] = poles.map((p) => {
    return [p.latitude, p.longitude, 0.0]
  })
  const [selectedPole, setSelectedPole] = useState<PoleData | null>(null)
  const [aggLevel, setAggLevel] = useState('last30Days')
  const [filterCoronaLow, setFilterCoronaLow] = useState(false)
  const [filterCoronaMedium, setFilterCoronaMedium] = useState(false)
  const [filterCoronaHigh, setFilterCoronaHigh] = useState(false)
  const [filterSuspectedSurfaceDischarge, setFilterSuspectedSurfaceDischarge] =
    useState(false)
  const [filterConfirmedSurfaceDischarge, setFilterConfirmedSurfaceDischarge] =
    useState(false)
  const [filterSuspectedArcing, setFilterSuspectedArcing] = useState(false)
  const [filterConfirmedArcing, setFilterConfirmedArcing] = useState(false)
  const [filterVegetationType, setFilterVegetationType] = useState('all')

  const eventColors = {
    'Low Corona': '#00bf28',
    'Medium Corona': '#0055a9',
    'High Corona': '#5b17b0',
    'Suspected Surface Discharge': '#c3a400',
    'Confirmed Surface Discharge': '#ff7e00',
    'Suspected Arcing': '#ff10d7',
    'Confirmed Arcing': '#ff102b'
  }

  const seeMoreInfoForPole = async (pole: PoleData | null) => {
    const formatDate = (date: Date) => {
      return date.toISOString().split('T')[0]
    }
    const getDateNDaysAgo = (daysAgo: number) => {
      return formatDate(new Date(Date.now() - daysAgo * 24 * 60 * 60 * 1000))
    }
    if (null !== pole) {
      pole = { ...pole } // Using pole from selector. It's readonly. Make it writeable. Kinda hacky.
      const poleId = pole.id
      if (!(pole.id in loadedEventsByNodeId)) {
        setLoadingData(true)
        // Need to load and set pole data.

        const start1 = getDateNDaysAgo(15)
        const start2 = getDateNDaysAgo(30)

        const eventData1 = await loadPoleEventData({
          poleId,
          startDayStr: start1
        })
        const eventData2 = await loadPoleEventData({
          poleId,
          startDayStr: start2,
          endDayStr: start1
        })
        const events = [
          ...(eventData1.events || []),
          ...(eventData2.events || [])
        ]
        setLoadedEventsByNodeId({
          ...loadedEventsByNodeId,
          [poleId]: { loadedAt: new Date(), events }
        })
        pole.events = events
        setLoadingData(false)
      } else {
        pole.events = loadedEventsByNodeId[poleId].events
      }
    }
    setSelectedPole(pole)
  }

  const anyFiltersApplied =
    filterCoronaLow ||
    filterCoronaMedium ||
    filterCoronaHigh ||
    filterSuspectedSurfaceDischarge ||
    filterConfirmedSurfaceDischarge ||
    filterSuspectedArcing ||
    filterConfirmedArcing ||
    filterVegetationType !== 'all'

  let filteredPoles = poles
  if (anyFiltersApplied) {
    filteredPoles = poles.filter((pole) => {
      if (
        filterCoronaLow &&
        pole.coronaCounts[aggLevel] &&
        pole.coronaCounts[aggLevel][1] > 0
      ) {
        return true
      } else if (
        filterCoronaMedium &&
        pole.coronaCounts[aggLevel] &&
        pole.coronaCounts[aggLevel][2] > 0
      ) {
        return true
      } else if (
        filterCoronaHigh &&
        pole.coronaCounts[aggLevel] &&
        pole.coronaCounts[aggLevel][3] > 0
      ) {
        return true
      } else if (
        filterSuspectedSurfaceDischarge &&
        pole.surfaceDischargeCounts[aggLevel] &&
        pole.surfaceDischargeCounts[aggLevel][1] > 0
      ) {
        return true
      } else if (
        filterConfirmedSurfaceDischarge &&
        pole.surfaceDischargeCounts[aggLevel] &&
        pole.surfaceDischargeCounts[aggLevel][2] > 0
      ) {
        return true
      } else if (
        filterSuspectedArcing &&
        pole.arcingCounts[aggLevel] &&
        pole.arcingCounts[aggLevel][1] > 0
      ) {
        return true
      } else if (
        filterConfirmedArcing &&
        pole.arcingCounts[aggLevel] &&
        pole.arcingCounts[aggLevel][2] > 0
      ) {
        return true
      } else if (pole.vegetationTypes.indexOf(filterVegetationType) > -1) {
        return true
      }
      return false
    })
  }

  const filtersSection = (
    <div className='mx-4 my-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
      <div className='flex items-center'>
        <span>Aggregate over:&nbsp;</span>
        <Dropdown
          name='aggregationPeriod'
          options={[
            { id: 'last24Hours', label: 'Last 24 Hours' },
            { id: 'last7Days', label: 'Last 7 Days' },
            { id: 'last30Days', label: 'Last 30 Days' }
          ]}
          value={aggLevel}
          onChange={(e) => setAggLevel(e.target.value)}
        />
      </div>
      <div className='flex flex-col'>
        <span>Must include:</span>
        <div className='flex flex-col space-y-2'>
          <Checkbox
            label='Confirmed Arcing'
            name='confirmedArcing'
            checked={filterConfirmedArcing}
            onChange={(e) => setFilterConfirmedArcing(e.target.checked)}
          />
          <Checkbox
            label='Suspected Arcing'
            name='suspectedArcing'
            checked={filterSuspectedArcing}
            onChange={(e) => setFilterSuspectedArcing(e.target.checked)}
          />
          <Checkbox
            label='Confirmed Surface Discharge / Surface Tracking'
            name='confirmedTracking'
            checked={filterConfirmedSurfaceDischarge}
            onChange={(e) =>
              setFilterConfirmedSurfaceDischarge(e.target.checked)
            }
          />
          <Checkbox
            label='Suspected Surface Discharge / Surface Tracking'
            name='suspectedSurfaceDischarge'
            checked={filterSuspectedSurfaceDischarge}
            onChange={(e) =>
              setFilterSuspectedSurfaceDischarge(e.target.checked)
            }
          />
          <Checkbox
            label='High Corona'
            name='highCorona'
            checked={filterCoronaHigh}
            onChange={(e) => setFilterCoronaHigh(e.target.checked)}
          />
          <Checkbox
            label='Medium Corona'
            name='mediumCorona'
            checked={filterCoronaMedium}
            onChange={(e) => setFilterCoronaMedium(e.target.checked)}
          />
          <Checkbox
            label='Low Corona'
            name='lowCorona'
            checked={filterCoronaLow}
            onChange={(e) => setFilterCoronaLow(e.target.checked)}
          />
        </div>
      </div>
      <div className='flex items-center'>
        <span>Vegetation Type:&nbsp;</span>
        <Dropdown
          name='vegetationType'
          options={[
            { id: 'all', label: 'All' },
            { id: 'shrubs', label: 'Shrubs' },
            { id: 'grass', label: 'Grass' },
            { id: 'deciduous', label: 'Deciduous Forest' },
            { id: 'mixedwood', label: 'Mixed Wood' },
            { id: 'uplandconnonpine', label: 'Upland Coniferous (Non-pine)' },
            {
              id: 'lowlandconblackspruce',
              label: 'Lowland Coniferous (Black Spruce)'
            },
            { id: 'lowlandconlarch', label: 'Lowland Coniferous (Larch)' },
            { id: 'shrubbywetlands', label: 'Non-treed Shrubby Wetlands' },
            { id: 'openwetlands', label: 'Non-treed Open Wetlands' }
          ]}
          value={filterVegetationType}
          onChange={(e) => setFilterVegetationType(e.target.value)}
        />
      </div>
    </div>
  )

  const getPoleHealth = (pole: PoleData, aggLevel: string): string => {
    if (!pole.coronaCounts[aggLevel]) {
      return 'grey'
    }
    let poleCondition = 'blue'
    if (
      pole.coronaCounts[aggLevel][1] > 0 &&
      pole.coronaCounts[aggLevel][2] <= 0 &&
      pole.coronaCounts[aggLevel][3] <= 0 &&
      pole.surfaceDischargeCounts[aggLevel][1] <= 0 &&
      pole.surfaceDischargeCounts[aggLevel][2] <= 0 &&
      pole.arcingCounts[aggLevel][1] <= 0 &&
      pole.arcingCounts[aggLevel][2] <= 0
    ) {
      poleCondition = 'green'
    }
    if (
      (pole.coronaCounts[aggLevel][2] > 0 ||
        pole.coronaCounts[aggLevel][3] > 0) &&
      pole.surfaceDischargeCounts[aggLevel][1] <= 0 &&
      pole.surfaceDischargeCounts[aggLevel][2] <= 0 &&
      pole.arcingCounts[aggLevel][1] <= 0 &&
      pole.arcingCounts[aggLevel][2] <= 0
    ) {
      poleCondition = 'yellow'
    }
    if (pole.surfaceDischargeCounts[aggLevel][1] > 0) {
      poleCondition = 'orange'
    }
    if (
      pole.surfaceDischargeCounts[aggLevel][2] > 0 ||
      pole.arcingCounts[aggLevel][1] > 0 ||
      pole.arcingCounts[aggLevel][2] > 0
    ) {
      poleCondition = 'red'
    }
    return poleCondition
  }

  const countStats = [
    {
      title: 'Poles Monitored',
      n: (poles || []).length
    },
    {
      title: 'Poles at High Risk',
      n: (poles || []).filter((pole) => {
        const toReturn =
          (pole.arcingCounts['last30Days'][1] > 0 ||
            pole.arcingCounts['last30Days'][2] > 0) &&
          true // TODO: Use actual wildfire risk data.
        return toReturn
      }).length
    },
    {
      title: 'Extreme Low Health Poles',
      n: (poles || []).filter((pole) => {
        return 'red' === getPoleHealth(pole, aggLevel)
      }).length,
      icons: [redIcon]
    },
    {
      title: 'Low Health Poles',
      n: (poles || []).filter((pole) => {
        return 'orange' === getPoleHealth(pole, aggLevel)
      }).length,
      icons: [orangeIcon]
    },
    {
      title: 'Moderately Healthy Poles',
      n: (poles || []).filter((pole) => {
        return 'yellow' === getPoleHealth(pole, aggLevel)
      }).length,
      icons: [yellowIcon]
    },
    {
      title: 'Mostly Healthy Poles',
      n: (poles || []).filter((pole) => {
        return 'green' === getPoleHealth(pole, aggLevel)
      }).length,
      icons: [greenIcon]
    },
    {
      title: 'Healthy Poles',
      n: (poles || []).filter((pole) => {
        return 'blue' === getPoleHealth(pole, aggLevel)
      }).length,
      icons: [blueIcon]
    }
  ]

  const countStatsSection = (
    <div className='flex flex-wrap justify-center'>
      {countStats.map((item, index) => (
        <div key={index} className='flex flex-col items-center p-4'>
          <div className='flex items-center space-x-2'>
            {item.icons && item.icons.length > 0 && (
              <span
                dangerouslySetInnerHTML={{
                  __html: String(item.icons[0].options.html)
                }}
              ></span>
            )}
            <CountUp
              start={0}
              end={item.n}
              duration={2.5}
              className='text-3xl font-bold'
            />
          </div>
          <span className='text-sm text-gray-600'>{item.title}</span>
        </div>
      ))}
    </div>
  )

  return (
    <div>
      <LoadingOverlay enabled={loadingData} />
      {countStatsSection}
      <Accordion heading='Filters'>{filtersSection}</Accordion>
      {selectedPole !== null ? (
        <div className='fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center overflow-auto'>
          <div className='max-w-[95vw] max-h-[85vh] w-full h-full bg-white p-8 rounded shadow-lg z-50'>
            <div className='w-full h-full overflow-auto'>
              <div className='flex justify-between'>
                <button
                  onClick={() => seeMoreInfoForPole(null)}
                  className='bg-red-500 hover:bg-red-800 text-white rounded px-4 py-2'
                >
                  Close
                </button>
                <div className='mx-4'>
                  Information for Pole {selectedPole.info.lineNumber}-
                  {selectedPole.info.poleNumber} ({selectedPole.deviceId}) at (
                  {selectedPole.latitude},{selectedPole.longitude})
                </div>
              </div>
              <PoleInfo pole={selectedPole} eventColors={eventColors} />
            </div>
          </div>
        </div>
      ) : null}
      <div
        style={{
          display: selectedPole === null && !loadingData ? 'block' : 'none'
        }}
      >
        <MapContainer
          center={[52.9889, -112.9125]}
          zoom={14}
          scrollWheelZoom={false}
          style={{ height: '60vh', width: '100%' }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
          {false ? <HeatmapLayer points={heatMapPoints} /> : null}
          {filteredPoles.map((pole) => {
            const poleCondition = getPoleHealth(pole, aggLevel)
            let icon = blueIcon
            if ('green' === poleCondition) {
              icon = greenIcon
            } else if ('yellow' === poleCondition) {
              icon = yellowIcon
            } else if ('orange' === poleCondition) {
              icon = orangeIcon
            } else if ('red' === poleCondition) {
              icon = redIcon
            } else if ('grey' === poleCondition) {
              icon = greyIcon
            }

            const mostRecentEvent = pole.mostRecentEvent
            const formattedTimeForMostRecentMeasurement =
              mostRecentEvent !== null
                ? mostRecentEvent.timestamp.toISOString().split('T')[0] +
                  ' ' +
                  mostRecentEvent.timestamp
                    .toTimeString()
                    .split(' ')[0]
                    .slice(0, 5)
                : ''

            const safeGetCount = (
              count: { [k: string]: { [i: number]: number } },
              aggLevel: string,
              n: number
            ) => {
              if (!count) {
                return 0
              } else if (!count[aggLevel]) {
                return 0
              } else if (!count[aggLevel][n]) {
                return 0
              }
              return count[aggLevel][n] || 0
            }
            return (
              <Marker
                key={pole.id}
                position={[pole.latitude, pole.longitude]}
                icon={icon}
              >
                {mostRecentEvent !== null ? (
                  <Popup>
                    <div className=''>
                      {colorToHealthExplanation(poleCondition)}
                    </div>
                    <div className='pt-2'>
                      <span className='font-bold'>
                        Most recent measurement (
                        {formattedTimeForMostRecentMeasurement}
                        ):
                      </span>
                      <ul className='list-disc pl-5'>
                        <li>
                          {coronaLevelToCategory(mostRecentEvent.coronaLevel)}
                        </li>
                        <li>
                          {surfaceDischargeLevelToCategory(
                            mostRecentEvent.surfaceDischargeLevel
                          )}
                        </li>
                        <li>
                          {arcingLevelToCategory(mostRecentEvent.arcingLevel)}
                        </li>
                      </ul>
                    </div>
                    <p>
                      {safeGetCount(pole.arcingCounts, aggLevel, 1) +
                        safeGetCount(pole.arcingCounts, aggLevel, 2)}
                      &nbsp;arcing events,&nbsp;
                      {safeGetCount(pole.surfaceDischargeCounts, aggLevel, 1) +
                        safeGetCount(pole.surfaceDischargeCounts, aggLevel, 2)}
                      &nbsp;surface discharge / surface tracking events,
                      and&nbsp;
                      {safeGetCount(pole.coronaCounts, aggLevel, 1) +
                        safeGetCount(pole.coronaCounts, aggLevel, 2) +
                        safeGetCount(pole.coronaCounts, aggLevel, 3)}
                      &nbsp;corona events detected in selected period.
                    </p>
                    <button
                      onClick={() => seeMoreInfoForPole(pole)}
                      className='bg-white text-blue-500 hover:text-blue-800 underline px-4 py-2'
                    >
                      See more
                    </button>
                    {poleCondition === 'red' ? (
                      <p className='font-bold'>
                        Given environmental conditions and pole activity, this
                        pole is at significant risk.
                      </p>
                    ) : null}
                  </Popup>
                ) : null}
              </Marker>
            )
          })}
        </MapContainer>
      </div>
    </div>
  )
}

export default TransmissionLinePage
