import React from 'react'

interface InputProps {
  type: string
  name: string
  value: number | string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
}

const Input: React.FC<InputProps> = ({
  type,
  name,
  value,
  onChange,
  placeholder
}) => (
  <input
    type={type}
    name={name}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    className='p-2 text-gray-900 border border-gray-300 rounded'
  />
)

export default Input
