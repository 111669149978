import React from 'react'
import { useRecoilState } from 'recoil'
import { isLoggedInState } from '../../state/authState'
import { logout } from '../../services/authService'
import { useLocation } from 'wouter'

const AuthButton: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState)
  const [, navigate] = useLocation()

  const handleLogin = () => {
    navigate('/login')
  }

  const handleLogout = async () => {
    await logout()
    setIsLoggedIn(false)
    navigate('/')
  }

  return (
    <button
      onClick={async () => {
        isLoggedIn ? await handleLogout() : await handleLogin()
      }}
      className='px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-700 transition-colors'
    >
      {isLoggedIn ? 'Logout' : 'Login'}
    </button>
  )
}

export default AuthButton
