import React, { useState } from 'react'

interface TabProps {
  tabs: { label: string; content: React.ReactNode }[]
  vertical?: boolean
}

const Tabs: React.FC<TabProps> = ({ tabs, vertical = false }) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div className={`flex ${vertical ? 'flex-row' : 'flex-col'}`}>
      <div
        className={`flex ${vertical ? 'flex-col' : 'flex-row'} border-b border-gray-200`}
      >
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`px-4 py-2 focus:outline-none ${activeTab === index ? 'border-blue-500 text-blue-500' : 'border-transparent text-gray-500'} ${vertical ? 'border-l-2' : 'border-b-2'}`}
            onClick={() => setActiveTab(index)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className='p-4'>{tabs[activeTab].content}</div>
    </div>
  )
}

export default Tabs
