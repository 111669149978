import React from 'react'
import LoginForm from '../components/molecules/LoginForm'

const LoginPage = () => (
  <div className='my-4'>
    <LoginForm />
  </div>
)

export default LoginPage
