import React from 'react'

interface DropdownProps {
  name: string
  options: Array<{ id: number | string | null; label: string }>
  value: number | string | null | undefined
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const Dropdown: React.FC<DropdownProps> = ({
  name,
  options,
  value,
  onChange
}) => (
  <select
    name={name}
    value={value === null ? '' : value} // Convert null to empty string for the value attribute
    onChange={onChange}
    className='p-2 border border-gray-300 rounded'
  >
    {options.map((option) => (
      <option
        key={option.id === null ? 'null' : option.id}
        value={option.id === null ? '' : option.id}
      >
        {option.label}
      </option>
    ))}
  </select>
)

export default Dropdown

/*
import React from 'react'

interface DropdownProps {
  name: string
  options: Array<{ id: number | string; label: string }>
  value: number | string
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const Dropdown: React.FC<DropdownProps> = ({
  name,
  options,
  value,
  onChange
}) => (
  <select
    name={name}
    value={value}
    onChange={onChange}
    className='p-2 border border-gray-300 rounded'
  >
    {options.map((option) => (
      <option key={option.id} value={option.id}>
        {option.label}
      </option>
    ))}
  </select>
)

export default Dropdown
*/
