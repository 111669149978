import React, { useState } from 'react'
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import {
  //format,
  subDays,
  subWeeks,
  isBefore,
  isAfter,
  addDays,
  addWeeks,
  addMonths
} from 'date-fns'

import Dropdown from '../atoms/Dropdown'

import { PoleEventData } from '../../types/poles/PoleData'

import { camelCaseToSpaced } from '../../services/stringServices'

export interface CorrelationPlaygroundProps {
  events: PoleEventData[]
  eventColors: { [key: string]: string }
}

const CorrelationPlayground: React.FC<CorrelationPlaygroundProps> = ({
  events,
  eventColors
}) => {
  console.log(eventColors)
  const firstCause = 'temperature'
  const firstEffect = 'coronaLevel'
  const [zoomLevel, setZoomLevel] = useState('1M')
  const [currentDate] = useState(new Date())
  type varType =
    | 'coronaLevel'
    | 'arcingLevel'
    | 'surfaceDischargeLevel'
    | 'temperature'
  const [cause, setCause] = useState<varType>(firstCause)
  const [effect, setEffect] = useState<varType>(firstEffect)

  const getStartDate = () => {
    if (zoomLevel === '1D') {
      return subDays(currentDate, 1)
    } else if (zoomLevel === '1W') {
      return subWeeks(currentDate, 1)
    } else if (zoomLevel === '1M') {
      return subDays(currentDate, 30)
    }
    return subDays(currentDate, 30)
  }

  const getEndDate = () => {
    if (zoomLevel === '1D') {
      return addDays(getStartDate(), 1)
    } else if (zoomLevel === '1W') {
      return addWeeks(getStartDate(), 1)
    } else if (zoomLevel === '1M') {
      return addMonths(getStartDate(), 1)
    }
    return addMonths(getStartDate(), 1)
  }
  console.log(getEndDate())

  const filteredEvents = events.filter((event) => {
    const startDate = getStartDate()
    return (
      isAfter(event.timestamp, startDate) &&
      isBefore(event.timestamp, currentDate)
    )
  })

  // Slight hack.
  const mapKeysToLabels = (k: string): string => {
    if (k === 'surfaceDischargeLevel') {
      return 'Surface Discharge / Surface Tracking Level'
    }
    return camelCaseToSpaced(k)
  }

  interface DropdownOption {
    id: string
    label: string
  }
  let causeEffectOptions: DropdownOption[] = []
  if (filteredEvents.length > 0) {
    causeEffectOptions = Object.keys(filteredEvents[0]).map((k) => {
      return { id: k, label: mapKeysToLabels(k) }
    })
  }

  const filterEffectOptions = (option: DropdownOption) => {
    return (
      [
        'vegetationTypes',
        'poleId',
        'id',
        'description',
        'latitude',
        'longitude',
        'timestamp',
        'rfMagnitude',
        'battery'
      ].indexOf(option.id) < 0
    )
  }

  const filterCauseOptions = (option: DropdownOption) => {
    return (
      [
        'vegetationTypes',
        'poleId',
        'id',
        'description',
        'latitude',
        'longitude',
        'timestamp',
        'rfMagnitude',
        'battery'
      ].indexOf(option.id) < 0
    )
  }

  let yAxisTicks = undefined
  if (['surfaceDischargeLevel', 'arcingLevel'].indexOf(effect) >= 0) {
    yAxisTicks = [0, 1, 2]
  } else if ('coronaLevel' === cause) {
    yAxisTicks = [0, 1, 2, 3]
  }

  let xAxisTicks = undefined
  if (['surfaceDischargeLevel', 'arcingLevel'].indexOf(cause) >= 0) {
    xAxisTicks = [0, 1, 2]
  } else if ('coronaLevel' === cause) {
    xAxisTicks = [0, 1, 2, 3]
  }

  const handleZoomChange = (level: string) => {
    setZoomLevel(level)
  }

  // Should normally also accept props: <not sure> too.
  const tooltipFormatter = (value: number, name: string): string[] => {
    return [`${value}`, camelCaseToSpaced(name)]
  }

  const processedData = Array.from(filteredEvents).sort((a, b) => {
    if (a[cause] > b[cause]) {
      return 1
    } else if (a[cause] < b[cause]) {
      return -1
    }
    return 0
  })

  if (filteredEvents.length > 0) {
    return (
      <div className='p-4'>
        <div className='flex justify-between mb-4'>
          <div>
            <span>Effect (y):&nbsp;</span>
            <Dropdown
              name='effect'
              options={causeEffectOptions.filter(filterEffectOptions)}
              value={effect}
              onChange={(e) => setEffect(e.target.value as varType)}
            />
          </div>
          <div>
            <span>Cause (x):&nbsp;</span>
            <Dropdown
              name='cause'
              options={causeEffectOptions.filter(filterCauseOptions)}
              value={cause}
              onChange={(e) => setCause(e.target.value as varType)}
            />
          </div>
        </div>
        <div className='flex justify-center mb-4'>
          <div>
            <button
              onClick={() => handleZoomChange('1D')}
              className={`px-4 py-2 ${zoomLevel === '1D' ? 'bg-gray-800 text-white' : 'bg-gray-200'}`}
            >
              1 Day
            </button>
            <button
              onClick={() => handleZoomChange('1W')}
              className={`px-4 py-2 ${zoomLevel === '1W' ? 'bg-gray-800 text-white' : 'bg-gray-200'}`}
            >
              1 Week
            </button>
            <button
              onClick={() => handleZoomChange('1M')}
              className={`px-4 py-2 ${zoomLevel === '1M' ? 'bg-gray-800 text-white' : 'bg-gray-200'}`}
            >
              1 Month
            </button>
          </div>
        </div>

        <div style={{ width: '100%', height: '400px' }}>
          <ResponsiveContainer width='100%' height='100%'>
            <ScatterChart
              width={500}
              height={400}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0
              }}
            >
              <Legend />
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                type='number'
                dataKey={cause}
                ticks={xAxisTicks}
                label={{
                  value: camelCaseToSpaced(cause),
                  position: 'insideBottomRight',
                  offset: 0
                }}
              />
              <YAxis
                type='number'
                ticks={yAxisTicks}
                dataKey={effect}
                label={{
                  value: camelCaseToSpaced(effect),
                  angle: -90,
                  position: 'insideLeft'
                }}
              />
              <Tooltip formatter={tooltipFormatter} />
              <Scatter
                data={processedData}
                name={camelCaseToSpaced(effect)}
                //fill={eventColors[effect]}
                fill={'purple'}
              />
            </ScatterChart>
          </ResponsiveContainer>
        </div>
      </div>
    )
  } else {
    return <p>No data to show!</p>
  }
}

export default CorrelationPlayground
