import React, { useState } from 'react'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import {
  format,
  subDays,
  subWeeks,
  subMonths,
  isBefore,
  isAfter
  //addDays,
  //addWeeks,
  //addMonths
} from 'date-fns'

import { PoleEventData } from '../../types/poles/PoleData'

function deepCopy<T>(obj: T): T {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  if (obj instanceof Date) {
    return new Date(obj.getTime()) as unknown as T
  }

  if (Array.isArray(obj)) {
    const arrCopy = (obj as unknown as Array<unknown>).map((item) =>
      deepCopy(item)
    )
    return arrCopy as unknown as T
  }

  if (obj instanceof Object) {
    const objCopy: { [key: string]: unknown } = {}
    Object.keys(obj).forEach((key) => {
      objCopy[key] = deepCopy((obj as { [key: string]: unknown })[key])
    })
    return objCopy as T
  }

  throw new Error("Unable to copy object! Its type isn't supported.")
}

interface EventCount {
  timestamp: Date
  counts: {
    [key: string]: number
  }
}

interface EventAccumulator {
  [d: string]: EventCount
}

interface EventLogProps {
  events: PoleEventData[]
  eventColors: { [key: string]: string }
}

const EventLog: React.FC<EventLogProps> = ({ events, eventColors }) => {
  const [dataMode, setDataMode] = useState('count')
  const [zoomLevel, setZoomLevel] = useState('1M')
  //const [currentDate, setCurrentDate] = useState(new Date())
  const [currentDate] = useState(new Date())

  // Should normally also accept name: string, props: <not sure> too.
  const tooltipFormatter = (value: number): string => {
    if (dataMode === 'percentage') {
      return `${(value + 0.0).toFixed(2)}%`
    }
    return `${value}`
  }

  const formatDate = (value: number | string | Date): string => {
    let fmtStr = 'HH'
    if (zoomLevel !== '1D') {
      fmtStr = 'yyyy-MM-dd'
    }
    return format(new Date(value), fmtStr)
  }

  const getStartDate = () => {
    if (zoomLevel === '1D') {
      return subDays(currentDate, 1)
    } else if (zoomLevel === '1W') {
      return subWeeks(currentDate, 1)
    } else if (zoomLevel === '1M') {
      return subMonths(currentDate, 1)
    }
    return subMonths(currentDate, 1)
  }

  /*
  const getEndDate = () => {
    if (zoomLevel === '1D') {
      return addDays(getStartDate(), 1)
    } else if (zoomLevel === '1W') {
      return addWeeks(getStartDate(), 1)
    } else if (zoomLevel === '1M') {
      return addMonths(getStartDate(), 1)
    }
    return addMonths(getStartDate(), 1)
  }
  */

  const filteredEvents = events.filter((event) => {
    const startDate = getStartDate()
    return (
      isAfter(event.timestamp, startDate) &&
      isBefore(event.timestamp, currentDate)
    )
  })

  const filteredEventCounts = filteredEvents.reduce(
    (acc: EventAccumulator, curr: PoleEventData): EventAccumulator => {
      const key = formatDate(curr.timestamp)
      acc[key] = acc[key] || {
        counts: Object.fromEntries(Object.keys(eventColors).map((k) => [k, 0])),
        timestamp: curr.timestamp
      }
      const coronaLevels = [
        'No Corona',
        'Low Corona',
        'Medium Corona',
        'High Corona'
      ]
      const surfaceDischargeLevels = [
        'No Surface Discharge',
        'Suspected Surface Discharge',
        'Confirmed Surface Discharge'
      ]
      const arcingLevels = ['No Arcing', 'Suspected Arcing', 'Confirmed Arcing']
      const coronaLevel = coronaLevels[curr.coronaLevel]
      const surfaceDischargeLevel =
        surfaceDischargeLevels[curr.surfaceDischargeLevel]
      const arcingLevel = arcingLevels[curr.arcingLevel]
      acc[key].counts[coronaLevel] = acc[key].counts[coronaLevel] || 0
      acc[key].counts[coronaLevel] += 1
      acc[key].counts[surfaceDischargeLevel] =
        acc[key].counts[surfaceDischargeLevel] || 0
      acc[key].counts[surfaceDischargeLevel] += 1
      acc[key].counts[arcingLevel] = acc[key].counts[arcingLevel] || 0
      acc[key].counts[arcingLevel] += 1
      return acc
    },
    {}
  )
  const filteredEventTotals = {
    counts: Object.fromEntries(
      Object.keys(filteredEventCounts).map((k) => [k, 0])
    )
  }
  for (const [dateKey, obj] of Object.entries(filteredEventCounts)) {
    for (const [type, n] of Object.entries(obj.counts)) {
      if (
        [
          'No Corona',
          'No Surface Discharge',
          'No Surface Discharge / Surface Tracking',
          'No Arcing',
          //'undefined',
          'null'
        ].indexOf(type) < 0
      ) {
        filteredEventTotals.counts[dateKey] += n
      }
    }
  }
  const filteredEventPercentages = deepCopy(filteredEventCounts)
  for (const dateKey of Object.keys(filteredEventPercentages)) {
    const obj = filteredEventPercentages[dateKey]
    for (const eventType of Object.keys(obj.counts)) {
      filteredEventPercentages[dateKey].counts[eventType] /= Math.max(
        filteredEventTotals.counts[dateKey],
        1
      )
      filteredEventPercentages[dateKey].counts[eventType] *= 100.0
    }
  }
  const filteredEventData =
    dataMode === 'count' ? filteredEventCounts : filteredEventPercentages
  //const filteredStreamData = Object.values(filteredEventCounts).map((x) => {
  const filteredStreamData = Object.values(filteredEventData)
    .map((x) => {
      const obj = x.counts as { [key: string]: number | string }
      obj.timestamp = formatDate(x.timestamp.getTime())
      return obj
    })
    .sort((a, b) => {
      if (a.timestamp > b.timestamp) {
        return 1
      } else if (b.timestmap > a.timestamp) {
        return -1
      } else {
        return 0
      }
    })

  console.log('@@@@@@@@@@@@@@@@@@@@@@@')
  console.log(filteredStreamData[0])
  console.log(filteredStreamData[1])
  console.log('@@@@@@@@@@@@@@@@@@@@@@@')

  const handleZoomChange = (level: string) => {
    setZoomLevel(level)
  }

  return (
    <div className='p-4'>
      <div className='flex justify-between mb-4'>
        <div>
          <button
            onClick={() => setDataMode('count')}
            className={`px-4 py-2 ${dataMode === 'count' ? 'bg-gray-800 text-white' : 'bg-gray-200'}`}
          >
            Counts
          </button>
          <button
            onClick={() => setDataMode('percentage')}
            className={`px-4 py-2 ${dataMode === 'percentage' ? 'bg-gray-800 text-white' : 'bg-gray-200'}`}
          >
            Percentage
          </button>
        </div>
        <div>
          <button
            onClick={() => handleZoomChange('1D')}
            className={`px-4 py-2 ${zoomLevel === '1D' ? 'bg-gray-800 text-white' : 'bg-gray-200'}`}
          >
            1 Day
          </button>
          <button
            onClick={() => handleZoomChange('1W')}
            className={`px-4 py-2 ${zoomLevel === '1W' ? 'bg-gray-800 text-white' : 'bg-gray-200'}`}
          >
            1 Week
          </button>
          <button
            onClick={() => handleZoomChange('1M')}
            className={`px-4 py-2 ${zoomLevel === '1M' ? 'bg-gray-800 text-white' : 'bg-gray-200'}`}
          >
            1 Month
          </button>
        </div>
      </div>

      <div style={{ width: '100%', height: '400px' }}>
        <ResponsiveContainer width='100%' height='100%'>
          <AreaChart
            width={500}
            height={400}
            data={filteredStreamData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0
            }}
          >
            <Legend />
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='timestamp' />
            {dataMode === 'percentage' ? (
              <YAxis domain={[0, 100.0]} allowDataOverflow={true} />
            ) : (
              <YAxis />
            )}
            <Tooltip formatter={tooltipFormatter} />
            {Object.entries(eventColors).map(([key, color]) => {
              return (
                <Area
                  key={key}
                  type='monotone'
                  dataKey={key}
                  stackId='1'
                  stroke={color}
                  fill={color}
                />
              )
            })}
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <div className='flex justify-center mb-4 p-4'>
        {dataMode === 'percentage' ? (
          <p>Area represents percentage of total events for each type.</p>
        ) : (
          <p>Area represents total volume for each event type.</p>
        )}
      </div>
    </div>
  )
}

export default EventLog
