import React, { useState } from 'react'

interface AccordionProps {
  heading: string
  children: React.ReactNode
}

const Accordion: React.FC<AccordionProps> = ({ heading, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className='w-full p-4'>
      <div
        className='cursor-pointer bg-gray-200 p-4 rounded-t-md'
        onClick={toggleAccordion}
      >
        {heading}
      </div>
      {isOpen && (
        <div className='border border-gray-300 p-4 rounded-b-md'>
          {children}
        </div>
      )}
    </div>
  )
}

export default Accordion
