import React from 'react'
import ResetPasswordForm from '../components/molecules/ResetPasswordForm'

const ResetPasswordPage = () => (
  <div className='my-4'>
    <ResetPasswordForm />
  </div>
)

export default ResetPasswordPage
