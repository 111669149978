import React from 'react'
import { Link } from 'wouter'
import AuthButton from '../atoms/AuthButton'

const Navbar: React.FC = () => {
  return (
    <nav className='bg-gradient-to-r from-white to-teal-200 text-black p-4'>
      <div className='w-full px-4 mx-auto flex justify-between items-center'>
        <div className='text-lg'>
          <Link href='/dashboard'>
            <img src='crwn_logo.png' alt='CRWN Logo' className='h-8' />
          </Link>
        </div>
        <div>
          {/* Other navigation items can go here */}
          <AuthButton />
        </div>
      </div>
    </nav>
  )
}

export default Navbar
