//import { useEffect, useState, useMemo } from 'react'
import { useEffect, useState } from 'react'
//import { useRecoilValueLoadable, useRecoilState } from 'recoil'
import { useRecoilValueLoadable } from 'recoil'
import { useLocation } from 'wouter'
import { loadPoleDataSelector } from '../state/poleData/selectors'
import { PoleData } from '../types/poles/PoleData'
import { isJwtExpired } from '../services/jwtService'

interface UsePoleDataArgs {
  setError?: (error: Error | null) => void
  setLoading?: (loading: boolean) => void
}

export const usePoleData = ({ setError, setLoading }: UsePoleDataArgs) => {
  const [, navigate] = useLocation()
  const poleDataLoadable = useRecoilValueLoadable<{
    data: { poles: PoleData[] }
  }>(loadPoleDataSelector)

  const [poles, setPoles] = useState<PoleData[]>([])

  useEffect(() => {
    const jwt = localStorage.getItem('authToken')
    let isMounted = true
    if (null === jwt || isJwtExpired(jwt || '')) {
      localStorage.removeItem('authToken')
      navigate('/login')
    } else if (poleDataLoadable.state === 'hasValue' && isMounted) {
      setLoading?.(false)
      setPoles(poleDataLoadable.contents.data?.poles)
    } else if (poleDataLoadable.state === 'hasError') {
      const error = poleDataLoadable.contents as Error
      const errorString = JSON.stringify(poleDataLoadable.contents)
      const strHas401 = errorString.includes('401')
      const asAny = error as object
      let has401Status = false
      if ('status' in asAny) {
        has401Status = asAny?.status === 401 || asAny?.status === '401'
      }
      const is401 = has401Status || error?.message.includes('401') || strHas401
      console.log(error)
      console.log(navigate)
      if (is401) {
        console.log('$$$$$$$$$$$$$$$')
      }
      //if (error.message.includes('401'))
      setError?.(error)
      setLoading?.(false)
    } else if (poleDataLoadable.state === 'loading') {
      setLoading?.(true)
    }
    return () => {
      isMounted = false
    }
  }, [poleDataLoadable, setError, setLoading])

  return { poles }
}

export default usePoleData
