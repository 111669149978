import React from 'react'

interface LoadingOverlayProps {
  enabled: boolean
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ enabled }) => {
  if (!enabled) {
    return null
  }

  return (
    <div className='fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50'>
      <div className='text-white text-lg'>Loading...</div>
      <div className='loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16 ml-4'></div>
    </div>
  )
}

export default LoadingOverlay
