import React from 'react'

interface CheckboxProps {
  name: string
  checked: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  label?: string
}

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  checked,
  onChange,
  label
}) => (
  <label className='inline-flex items-center space-x-2'>
    <input
      type='checkbox'
      name={name}
      checked={checked}
      onChange={onChange}
      className='form-checkbox text-blue-600 h-5 w-5'
    />
    {label && <span>{label}</span>}
  </label>
)

export default Checkbox
