import { selector } from 'recoil'
import {
  loadSummarizedPoleData,
  loadPoleEventData
} from '../../services/poleServices'
import { PoleEventData, PoleData } from '../../types/poles/PoleData'

export const loadPoleEventDataSelector = selector({
  key: 'loadPoleEventDataSelector',
  get: async () => {
    try {
      const data = await loadPoleEventData({ clientId: 3 })
      data.events = data.events.map((e: PoleEventData) => {
        e.timestamp = new Date(e.timestamp)
        e.timestamp = new Date(
          e.timestamp.getTime() + e.timestamp.getTimezoneOffset() * 60000
        )
        return e
      })
      return data
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error)
      throw error
    }
  }
})

export const loadPoleDataSelector = selector({
  key: 'loadPoleDataSelector',
  get: async () => {
    try {
      const result = await loadSummarizedPoleData({ clientId: 3 })
      const poles: PoleData[] = result.nodes.map((p: PoleData) => {
        p.mostRecentEvent.timestamp = new Date(p.mostRecentEvent.timestamp)
        p.mostRecentEvent.timestamp = new Date(
          p.mostRecentEvent.timestamp.getTime() +
            p.mostRecentEvent.timestamp.getTimezoneOffset() * 60000
        )
        return p
      })
      const data: { poles: PoleData[] } = {
        poles
      }
      return { data }
    } catch (error) {
      //console.error('There was a problem with the fetch operation:', error)
      throw error
    }
  }
  /*
  key: 'loadPoleDataSelector',
  get: async ({ get }) => {
    try {
      const poleEvents = get(loadPoleEventDataSelector)
      const poleData = eventToPoleData(poleEvents.events)
      const data: { poles: PoleData[] } = {
        poles: poleData
      }
      return { data }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error)
      throw error
    }
  }
  */
})
