export interface ParsedJwt {
  exp: number
  [name: string]: unknown
}

export const parseJwt = (token: string): ParsedJwt => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const isJwtExpired = (token: string): boolean => {
  if (!token) return true

  try {
    const decodedToken = parseJwt(token)
    const currentTime = Date.now() / 1000 // Current time in seconds

    return decodedToken.exp < currentTime
  } catch (error) {
    console.error('Error decoding token:', error)
    return true
  }
}
